<template>
    <Suspense>
        <template #default>
            <App />
        </template>
    </Suspense>
</template>

<script>
import App from './App.vue'

export default {
    name: 'Suspenser',
    components: {
        App
    }
}
</script>