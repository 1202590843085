import RequestUtils from "@/service/RequestUtils";

export default class ProductService {
  async getProductsPageWithPaginationParams(q, from, size) {
    const apiURL =
      process.env.VUE_APP_https://dip.api.devportal.adeo.cloud/backend-ares/v1 +
      "/api/products?q=" +
      q +
      "&from=" +
      from +
      "&size=" +
      size;

    return await RequestUtils.executeRequest(apiURL);
  }

  async getProductDetails(adeoKey, gtin) {
    const apiURL =
      process.env.VUE_APP_https://dip.api.devportal.adeo.cloud/backend-ares/v1 +
      "/api/products/productDetails?adeoKey=" +
      adeoKey +
      "&gtin=" +
      gtin;
    return await RequestUtils.executeRequest(apiURL);
  }

  async getProductPhotoUrl(adeoKey, buCode) {
    const apiURL =
      process.env.VUE_APP_https://dip.api.devportal.adeo.cloud/backend-ares/v1 +
      "/api/products/photoUrl?adeoKey=" +
      adeoKey +
      "&buCode=" +
      buCode;

    return await RequestUtils.executeRequest(apiURL);
  }
}
