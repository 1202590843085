<template>
    <div class="language_selector_container">
        <img class="selector__label__flag_image" :src="getFlagImagePath(currentLang.src)"
            :alt="getFlagImagePath(currentLang.src)" />
        <MDropdown :items="langs" size="s" id="defaultField" :placeholder="$t('SelectLanguage')"
            @update:model-value="updateLang">
            <template v-slot:item="{ item }">
                <div class="selector__label">
                    <img class="selector__label__flag_image" :src="getFlagImagePath(item.src)"
                        :alt="getFlagImagePath(item.src)" />
                    <div class="selector__label__labelText">
                        {{ item.label }}
                    </div>
                </div>
            </template>
        </MDropdown>
    </div>
</template>

<script>
import { MDropdown, MField } from '@mozaic-ds/vue-3';
import PhotoService from "@/service/PhotoService";

export default {
    name: "LanguageSelector",
    data() {
        return {
            photoService: new PhotoService(),
        }
    },
    components: {
        MDropdown,
        MField,
    },
    props: ["langs", "currentLang"],


    methods: {
        getFlagImagePath(name) {
            return this.photoService.getFlagImagePath(name);
        },
        updateLang(langValue) {
            const langCode = this.langs.filter(lang => lang.value === langValue).map(lang => lang.code)
            this.$emit("change-lang", langCode)
        }
    },
    emits: ["change-lang"]
};
</script>

<style lang="scss" scoped>
.language_selector_container {
    display: flex;
    flex-direction: row;
}

.selector {
    &__label {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &__flag_image {
            width: 30px;
            margin-right: 10px;
        }
    }
}
</style>