<template>
    <div class="card">
        <div class="card__mask" :id="product.id">
            <div class="card__mask__photo" :id="product.id" v-if="isLoadingPhoto">
                <div class="card__mask__photo__loader">
                    <MLoader v-bind="{ size: 'm' }" />
                </div>
            </div>
        </div>

        <MCard :title="product.name" :imgSrc="getImagePath()">
            <template v-slot:default>
                <div class="mc-card__body__carac">{{ $t('GTIN') }} : {{ product.gtin }}</div>
                <div class="mc-card__body__carac">ADEO KEY : {{ product.adeoKey }}</div>
                <div class="mc-card__body__carac">{{ $t('REFERENCE') }} : {{ product.reference }}</div>
            </template>
        </MCard>
    </div>
</template>

<script>
import { MCard, MLoader } from "@mozaic-ds/vue-3";
import PhotoService from "@/service/PhotoService";
import ProductService from "@/service/ProductService";

export default {
    name: "Card",

    props: ["product"],

    components: {
        MCard,
        MLoader
    },

    methods: {
        getImagePath() {
            return this.photoService.getImagePath(this.product.photoUrl)
        }
    },
    data() {
        return {
            photoService: new PhotoService(),
            productService: new ProductService(),
            isLoadingPhoto: false
        };
    },
    async created() {
        this.isLoadingPhoto = true
        this.product.photoUrl = await this.productService.getProductPhotoUrl(this.product.adeoKey, this.product.buCode)
            .then(response => response.text())
            .then(text => {
                this.isLoadingPhoto = false
                return text
            })
    },
}
</script>

<style lang="scss">
.card {
    position: relative;

    &__mask {
        position: absolute;
        z-index: 1;
        background-color: transparent;
        height: auto;
        width: 220px;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        display: flex;

        &__photo {
            width: 200px;
            height: 200px;
            background-color: rgba(25, 25, 25, 0.7);
            margin-top: 30px;
            margin-left: 15px;

            &__loader {
                margin-top: 50%;
            }
        }

    }
}

.mc-card {
    border-bottom: solid 1px #B3B3B3;
    height: 100%;
    padding-top: 30px;
    padding-left: 15px;
    padding-right: 15px;

    &__visual {
        background-color: white;
        width: 200px;
        height: 200px;
    }

    &__title {
        text-align: start;
        height: 110px;
    }

    &__body {
        text-align: start;

        &__carac {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
        }
    }

    &__content {
        width: 200px;
        word-wrap: break-word;
    }
}
</style>