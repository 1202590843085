<template>
    <div class="body">
        <div class="body__text"> {{ message }}</div>
        <div class="body__card-list">
            <div class="body__card-list__card" v-for="product in productsPage.content">
                <Card :product="product" @click="openProductDetails">
                </Card>
            </div>
        </div>

        <div class="body__footer" v-if="isSearchConclusive === true">
            <MPagination :value="productsPage.pageable.pageNumber + 1" :length="productsPage.totalPages" :pageLabel="'of'"
                @on-update-page="updatePage">
            </MPagination>
        </div>
    </div>
</template>

<script>
import Card from "@/components/Card.vue";
import { MPagination } from "@mozaic-ds/vue-3";

export default {
    name: "SearchResults",

    props: [
        "productsPage",
        "message",
        "productsPerPage",
        "isSearchConclusive"
    ],

    components: {
        Card,
        MPagination,
    },
    methods: {

        updatePage(value) {
            this.$emit("update-page", value);
        },

        openProductDetails(ev) {
            this.$emit("open-product-details", ev.target.id);
        }
    },

    emits: ["update-page", "open-product-details"],
}

</script>

<style lang="scss">
.body {
    background-color: #E7E7F0;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1050px;

    &__text {
        text-align: start;
        padding: 20px;
    }

    &__card-list {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
        max-width: 1000px;
        padding-bottom: 20px;
    }

    &__footer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        background-color: white;
        padding: 10px;
        border-top: solid 1px #B3B3B3;
        height: 30px;
        width: 100%
    }
}
</style>