<template>
  <router-view />
</template>

<script>
import { i18nextPromise } from './i18n';

!function (a, b, c, d, e, f) {
  a.ddjskey = e;
  a.ddoptions = f || null;
  var m = b.createElement(c), n = b.getElementsByTagName(c)[0];
  m.async = 1, m.src = d, n.parentNode.insertBefore(m, n)
}(window, document, "script", "https://bot.cdn.adeo.cloud/tags.js", "F9ED515A3A74399411D53119F49E89", { ajaxListenerPath: true, 'allowHtmlContentTypeOnCaptcha': true });

export default {
  name: 'App',
  async setup() {
    await i18nextPromise
    return {}
  }
}
</script> 

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
