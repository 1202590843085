import { createRouter, createWebHashHistory } from 'vue-router'
import AboutView from '../views/AboutView.vue'
import NotFound from '../views/NotFound.vue'

const routes = [
  { path: '/', name: 'home', component: AboutView },
  { path: '/ac/', redirect: '/' },
  { path: '/fans/', redirect: '/' },
  { path: '/404', name: 'NotFound', component: NotFound },
  { path: '/:catchAll(.*)', redirect:'404' }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
