<template>
    <Banner />

    <MHero :title="$t('title')" :center="true" :imgSrc="require('@/assets/header_picture.png')">
        <template v-slot:default>
            <p>{{ $t('description') }}</p>
            <SearchBar @send-search-string="sendSearchString"></SearchBar>
        </template>
    </MHero>
</template>

<script>
import { MHero } from "@mozaic-ds/vue-3";
import SearchBar from "@/components/SearchBar.vue";
import Banner from "@/components/Banner.vue";

export default {
    name: "Header",

    components: {
        MHero,
        SearchBar,
        Banner,
    },

    methods: {
        sendSearchString(searchString) {
            this.$emit("send-search-string", searchString);
        }
    },

    emits: ["send-search-string"],
}

</script>