<template>
    <MField id="search-bar" :label="$t('SearchInvite')">
        <MTextInput @input="updateSearchString" @keyup.enter="sendSearchString" id="defaultField" icon="DisplaySearch48"
            :placeholder="$t('SearchPlaceholder')" />
    </MField>
</template>

<script>
import { MTextInput, MField } from "@mozaic-ds/vue-3";

export default {
    name: "SearchBar",

    data() {
        return {
            searchString: "",
        };
    },

    components: {
        MTextInput,
        MField
    },

    methods: {

        updateSearchString(event) {
            this.searchString = event.target.value;
        },

        sendSearchString() {
            if (this.searchString.length > 0) {
                this.$emit("send-search-string", this.searchString);
            }
        },
    },

    emits: ["send-search-string"],
}
</script>

<style lang="scss">
.mc-field {
    text-align: left;
    max-width: 592px;
    margin: 0 auto;

    label {
        color: white;
    }

    .mc-left-icon-input {
        .mc-left-icon-input {
            &__icon {
                right: 0.75rem;
                left: auto;
            }

            &__input {
                padding-left: 0.75rem;
            }
        }
    }
}
</style>