<script setup>

</script>

<template>
  <h2>404 Not found. The page you are looking for does not exist.</h2>
</template>

<style scoped lang="scss">

</style>