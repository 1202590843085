<template>
  <Main></Main>
</template>

<script>
import Main from "@/components/Main.vue";

export default {
  name: "AboutView",
  components: {
    Main,
  }
};
</script>
