<template>
  <Header @send-search-string="sendSearchString"></Header>

  <div class="loader" v-if="isLoading === true">
    <MLoader v-bind="{ size: 'l' }" />
  </div>

  <div class="search-results" v-if="displaySearchResult === true">
    <SearchResults :productsPage="productsPage" :message="message" :productsPerPage="productsPerPage"
      :isSearchConclusive="isSearchConclusive" @open-product-details="openProductDetails" @update-page="updatePage" />
  </div>
</template>

<script>
import SearchResults from "@/components/SearchResults.vue";
import Header from "@/components/Header.vue";
import ProductDetails from "@/components/ProductDetails.vue";
import { MLoader } from "@mozaic-ds/vue-3";
import { t } from "i18next";

export default {
  name: "Body",

  props: ["productsPage", "displaySearchResult", "productsPerPage", "isLoading", "isSearchConclusive"],

  beforeUpdate() {
    this.message = this.getMessage();
  },

  components: {
    Header,
    SearchResults,
    ProductDetails,
    MLoader
  },
  methods: {
    sendSearchString(searchString) {
      this.searchString = searchString;

      console.log("sending search string : " + searchString);

      this.$emit("send-search-string", searchString);
    },
    getMessage() {
      return `${this.productsPage.totalElements} ${t('resultsMessage')} "${this.searchString}"`
    },
    openProductDetails(id) {
      this.$emit("display-product-details", id);
    },
    updatePage(value) {
      this.$emit("update-page", value);
    },
  },

  data() {
    return {
      message: "",
      searchString: "",
    };
  },

  emits: ["send-search-string", "display-product-details", "update-page"],
};
</script>

<style lang="scss">
.loader {
  margin-top: 30px;
}

.search-results {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
